import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import future from '../../assets/box2.jpg';
import welcome from '../../assets/welcome2.png';
import vision from '../../assets/vision2.PNG';
import './header.css';

const Header = (props) => {
    return (
        <div className="bolt__header section__padding" id="home">
            <div className="bolt__header-content">
                <h1 className="gradient__text">Welcome to BlueBolt, where promises are not just made, they're delivered. On time!</h1>
                {/* <h1 className="gradient__text">Let&apos;s Build Something Amazing with BlueBolt Logistics.</h1> */}
                <p>We pride ourselves on being the epitome of seamless logistics, passionately connecting businesses and consumers across India and the Asia Pacific regions. With our dedicated network and commitment to customer delight, BlueBolt ensures your packages and shipments reach their destination precisely when you expect them. Experience the power of punctuality with BlueBolt – where every delivery is a testament to our unwavering commitment: <b>Delivered on time, every time!</b></p>

                {/* <div className="bolt__header-content__input">
                    <input type="email" placeholder="Your Email Address" />
                    <button type="button">Get Started</button>
                </div> */}

                {/* <div className="bolt__header-content__people">
                    <img src={people} />
                    <p>1,600 people requested access a visit in last 24 hours</p>
                </div> */}
            </div>

            <div className="bolt__header-image">
                <img src={welcome} />
                {/* <img src={future} /> */}
            </div>
        </div>
    );
}

export default Header;