import React from 'react';
// import possibilityImage from '../../assets/air2.jpg';
import possibilityImage from '../../assets/3wheeler.jpg';
import './possibilty.css';

const Possibilty = (props) => {
    return (
        <div className="bolt__possibility section__padding" id="mission">
            <div className="bolt__possibility-image">
                <img src={possibilityImage} alt="possibility" />
            </div>
            <div className="bolt__possibility-content">
                {/* <h4>Request Early Access to Get Started</h4> */}
                <h1 className="gradient__text">Our Mission for a Connected World</h1>
                <p>
                    BlueBolt aspires to be the premier choice in the carriage and handling of packages and shipments, fostering customer delight through their dedicated network and time-definite deliveries. Their commitment is driven by passionate individuals across India and extending into the Asia Pacific regions, ensuring a seamless and efficient logistics experience. BlueBolt's focus lies in exceeding customer expectations, emphasizing timely and precise deliveries, and their passionate workforce remains at the heart of their success, spanning across India and the broader Asia Pacific territories. Their relentless dedication to customer satisfaction and their skilled team distinguish them as a leader in the industry.</p>
                <h4><a href="#contact">Request Early Access to Get Started</a></h4>
            </div>
        </div>
    );
}

export default Possibilty;