import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contactForm.css'; // Import the CSS file for styling
import formImg from "../../assets/blue_journal.png";


const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Email.js configuration
        const serviceId = 'service_09c90ik';
        const templateId = 'template_twyrtr5';
        const userId = 'E-TbormJmyUaVOUu_';

        // Send email using Email.js
        emailjs.sendForm(serviceId, templateId, e.target, userId)
            // emailjs.sendForm(serviceId, templateId, formData, userId)
            .then((response) => {
                console.log('Email sent successfully:', response);
                window.location.reload()
                // Reset the form after successful submission
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            })
            .catch((error) => {
                console.error('Email failed to send:', error);
            });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="contact-form-container" id='contact'>
            <div className='innerContainer'>
                <div className='innerContainer_image'>
                    <img className="contact-form-image" src={formImg} alt="Contact" />
                </div>
                <div className='innerContainer_form'>
                    <h2 style={{ color: 'white' }}>Contact Us</h2>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                        />
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
