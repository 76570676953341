import React from 'react';
import './teamMember.css'; // Import the CSS file for styling

const TeamMember = ({ name, photo, description }) => {
    return (
        <div className="team-member">
            <img
                className="team-member-photo" src={photo}
                alt={`${name}'s photo`}
            />
            <h2 className="team-member-name">{name}</h2>
            <p className="team-member-description">{description}</p>
        </div>
    );
};

export default TeamMember;