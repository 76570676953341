import React from 'react';
import TeamMember from '../teamMember/TeamMember';
import dummyPic from "../../assets/ai.png";
import LJ from "../../assets/lj.png";
import LJ2 from "../../assets/lj2.png";
// import BT from "../../assets/B.png";
import b2 from "../../assets/b2.png";
import HT from "../../assets/H.png";
import h2 from "../../assets/h2.png";
import TT from "../../assets/T.jpg";
import VA from "../../assets/VA.jpg";
import TR from "../../assets/TR.png";
import SB from "../../assets/SB.png";
import Deb from "../../assets/Deb.jpeg";
import San from "../../assets/s.png";
import './team.css';

const teamMembers = [
    {
        name: 'Lawrence Joseph',
        photo: LJ2,
        // photo: '../../assets/lj.png',
        description: 'Founder'
    },
    {
        name: 'Sandeep Mohanty',
        photo: San,
        description: 'Assistant Director'
    },
    {
        name: 'Srirang Bapat',
        photo: SB,
        description: 'Advisor'
    },
    {
        name: 'Thomas Mathew',
        photo: TT,
        description: 'Advisor'
    },
    {
        name: 'Tamil Rajan',
        photo: TR,
        description: 'Advisor'
    },
    {
        name: 'Vinayam Annadurai',
        photo: VA,
        description: 'Advisor'
    },
    {
        // name: '',
        photo: b2,
        description: 'CRM Director'
    },
    {
        // name: '',
        photo: h2,
        description: 'Operations Director'
    },
    // Add more team members as needed
];

const Team = () => {
    return (
        <div className='main_container' id='team'>
            <div className="bolt__features-container__feature-title">
                <div />
                <h1 className='heading' style={{ fontSize: '30px' }}>Our Management Team</h1>
            </div>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <TeamMember key={index} {...member} />
                ))}
            </div>
        </div>
    );
};

export default Team;
