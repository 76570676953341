
import React from 'react';
import { Footer, Blog, Possibility, Features, WhatGPT3, Header, Vision, Team, ContactForm } from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';

const App = (props) => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      <Vision />
      <Brand />
      <Team />
      <WhatGPT3 />
      {/* <Features /> */}
      <Possibility />
      <CTA />
      {/* <Blog /> */}
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;

// import logo from './logo.svg';
// import './App.css';
// import ResponsiveAppBar from './components/header';
// import Box from '@material-ui/core/Box';
// // import { palette } from '@mui/system';


// function App() {
//   return (
//     <div className="App">
//       {/* header */}
//       <div>
//         {/* <h1>Bolt</h1> */}
//         <ResponsiveAppBar />

//       </div>
//       {/* header */}

//       {/* body */}
//       <div>
//         <div class="wrapper">
//           <header class="header">My header</header>
//           <aside class="sidebar">Sidebar</aside>
//           <article class="content">
//             <h1>2 column, header and footer</h1>
//             <p>This example uses line-based positioning, to position the header and footer, stretching them across the grid.</p>
//           </article>

//           <aside class="sidebar">Sidebar2</aside>
//           <article class="content">
//             <h1>Using negative space</h1>
//             <p>This example uses line-based positioning, to position the header and footer, stretching them across the grid.</p>

//             <p>By making the content span a number of rows, we can then space the blocks out next to that content. Each in their own row.</p>
//           </article>

//           <aside class="sidebar">Sidebar3</aside>
//           <article class="content">
//             <h1>Using negative space</h1>
//             <p>This example uses line-based positioning, to position the header and footer, stretching them across the grid.</p>

//             <p>By making the content span a number of rows, we can then space the blocks out next to that content. Each in their own row.</p>
//           </article>

//           <aside class="sidebar">Sidebar4</aside>
//           <div class="media img-flexie">

//             <div class="img">
//               <img src="http://placehold.it/250x250" alt="Placeholder" />
//             </div>
//             <h2 class="title">Both my columns flex</h2>
//             <div class="content">
//               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vehicula vitae ligula sit amet maximus. Nunc auctor neque ipsum, ac porttitor elit lobortis ac. Vivamus ultrices sodales tellus et aliquam. Pellentesque porta sit amet nulla vitae luctus. Praesent quis risus id dolor venenatis condimentum.</p>
//             </div>
//             <div class="footer">
//               An optional footer goes here.
//             </div>
//           </div>


//           <footer class="footer">My footer</footer>
//         </div>
//       </div>
//       {/* body */}

//       {/* footer */}
//       <div class="bg-info p-2 my-2">
//         <p>Footer</p>
//       </div>
//       {/* footer */}
//     </div>
//   );
// }


// export default App;
