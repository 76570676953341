import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhtaGPT3 = (props) => {
    return (

        <div className="gpt3__whatgpt3 section__margin" id="wbolt">
            <div className="gpt3__whatgpt3-feature">
                <Feature title="What is BlueBolt focusing on?" text="BlueBolt highlights its focus on addressing challenges in the Indian courier, express, and parcel (CEP) market to promote faster adoption. BlueBolt aims to resolve key issues hindering the CEP sector's growth in India." />
            </div>
            <div className="gpt3__whatgpt3-heading">
                <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
                {/* <p>Explore the Library</p> */}
                {/* <h4><a href="#contact">Request Early Access to Get Started</a></h4> */}
            </div>
            <div className="gpt3__whatgpt3-container">
                <Feature title="Time Definite service" text="BlueBolt stands out by offering precise Time Definite services, guaranteeing businesses and consumers reliable, efficient, and punctual deliveries that provide peace of mind." />
                <Feature title="Enhance Capacity Fulfillment" text="BlueBolt strives to optimize package transportation in India's express industry by enhancing capacity fulfillment, ensuring a more efficient logistics network, timely deliveries, and reliable services for businesses and consumers, showcasing their commitment to elevating industry standards." />
                <Feature title="REVERSE LOGISTICS" text="BlueBolt stands out for its exceptional performance in reverse logistics, offering comprehensive services including pickups, quality checks, packing, transportation, and reselling. Their expertise in these areas underscores their commitment to providing top-notch solutions for reverse logistics needs." />
            </div>
        </div>

    );
}

export default WhtaGPT3;