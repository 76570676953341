import React from 'react';
import './feature.css';

const Feature = ({ title, text }) => {
    return (
        <div className="bolt__features-container__feature">
            <div className="bolt__features-container__feature-title">
                <div />
                <h1 style={{ fontSize: '40px', lineHeight: '50px' }}>{title}</h1>
            </div>
            <div className="bolt__features-container_feature-text">
                <p>{text}</p>
            </div>
        </div>
    );
}

export default Feature;