import React from 'react';
import boltLogo from "../../assets/logo.jpg";
import './footer.css';

const Footer = (props) => {
    return (
        <div className="bolt__footer section__padding">
            <div className="bolt__footer-heading">
                <h1 className="gradient__text">Do you want to step in to the future before others ?</h1>
            </div>

            {/* <div className="bolt__footer-btn">
                <p style={{ color: "white" }}>Request Early Access</p>
            </div> */}

            <div className="bolt__footer-links">
                <div className="bolt__footer-links_logo">
                    <img src={boltLogo} alt="bolt_logo" />
                    <p style={{ color: "white" }}>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p>
                </div>
                <div className="bolt__footer-links_div">
                    <h4>Links</h4>
                    <p style={{ color: "white" }}>Overons</p>
                    <p style={{ color: "white" }}>Social Media</p>
                    <p style={{ color: "white" }}>Counters</p>
                    <p style={{ color: "white" }}>Contact</p>
                </div>
                <div className="bolt__footer-links_div">
                    <h4>Company</h4>
                    <p style={{ color: "white" }}>Terms & Conditions </p>
                    <p style={{ color: "white" }}>Privacy Policy</p>
                    <p style={{ color: "white" }}>Contact</p>
                </div>
                <div className="bolt__footer-links_div">
                    <h4>Get in touch</h4>
                    <p style={{ color: "white" }}>Crechterwoord K12 182 DK Alknjkcb</p>
                    <p style={{ color: "white" }}>085-132567</p>
                    <p style={{ color: "white" }}>info@payme.net</p>
                </div>
            </div>

            <div className="bolt__footer-copyright">
                <p style={{ color: "white" }}>@2021 BOLT. All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;