import React from 'react';
// import possibilityImage from '../../assets/air2.jpg';
import possibilityImage from '../../assets/BoltPlane.png';
import truck from '../../assets/TRUCK.png';
import './vision.css';

const Vision = (props) => {
    return (
        <div className="bolt__possibility section__padding" id="vision">

            <div className="bolt__possibility-image">
                <img src={possibilityImage} alt="possibility" />
            </div>
            <div className="bolt__possibility-content">
                {/* <h4>Request Early Access to Get Started</h4> */}
                <h1 className="gradient__text" style={{ fontSize: 40 }}>Our Vision</h1>
                <p style={{ color: 'white' }}>To  Be the Best  in the carriage & handling of  packages  and  shipments with a  committed  network – by AIR & ROAD, to achieve CUSTOMER DELIGHT with TIME DEFINITE  deliveries.
                    We will EMPLOY- the Best  trained  people, an evolving  design  in TECHNOLOGY, uncluttered  infrastructure across India and  Asia pacific regions

                    Our Focus -  ASSURANCE GUARANTEED, SPEED, QUALITY, ANYWHERE IN COUNTRY
                </p>
                {/* <p>
                    BlueBolt aspires to be the premier choice in the carriage and handling of packages and shipments, fostering customer delight through their dedicated network and time-definite deliveries. Their commitment is driven by passionate individuals across India and extending into the Asia Pacific regions, ensuring a seamless and efficient logistics experience. BlueBolt's focus lies in exceeding customer expectations, emphasizing timely and precise deliveries, and their passionate workforce remains at the heart of their success, spanning across India and the broader Asia Pacific territories. Their relentless dedication to customer satisfaction and their skilled team distinguish them as a leader in the industry.</p> */}
                <h4><a href="#contact">Request Early Access to Get Started</a></h4>
            </div>
            {/* <div>
                <div className="bolt__possibility-image">
                    <img src={possibilityImage} alt="possibility" />
                </div>
                <div className="bolt__possibility-image">
                    <img src={truck} alt="possibility" />
                </div>
            </div> */}
            <div className="bolt__possibility-image">
                <img src={truck} alt="possibility" />
            </div>

        </div>
    );
}

export default Vision;