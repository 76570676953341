import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from "../../assets/logo.jpg";
import './navbar.css';

const Navbar = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false);

    const Menu = () => (
        <>
            <p><a href="#home">Home</a></p>
            <p><a href="#wbolt">Why BlueBolt</a></p>
            <p><a href="#vision">Our Vision</a></p>
            <p><a href="#team">Our team</a></p>
            <p><a href="#mission">Our Mission</a></p>
            {/* <p><a href="#features">Case Studies</a></p> */}
            <p><a href="#contact">Contact us</a></p>
        </>
    )
    return (
        <div className="bolt__navbar">
            <div className="bolt__navbar-links">
                <div className="bolt__navbar-links_logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="bolt__navbar-links_container">
                    <Menu />
                </div>
            </div>
            {/* <div className="bolt__navbar-sign">
                <p>Sign in</p>
                <button type="button">Sign up</button>
            </div> */}


            <div className="bolt__navbar-menu">
                {toggleMenu
                    ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
                {toggleMenu && (
                    <div className="bolt__navbar-menu_container scale-up-center">
                        <div className="bolt__navbar-menu_container-links">
                            <Menu />
                        </div>
                        {/* <div className="bolt__navbar-menu_container-links-sign">
                            <p>Sign in</p>
                            <button type="button">Sign up</button>
                        </div> */}
                    </div>
                )}
            </div>


        </div>
    );
}

export default Navbar;